import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

import { ThalamusCard } from "src/components/thalamus-card";
ThalamusCard;

import { YouTubeCard } from 'src/components/youtube-card';
YouTubeCard;

import { ColleagueAudit } from "./colleague-audit";
ColleagueAudit;

import { ThalamusHero } from 'src/components/thalamus-hero';
ThalamusHero;

import { CtxHero } from 'src/components/ctx-hero';
CtxHero;
// markup view ensures all required components are available
// content doesn't need to be in a slot
@customElement('markup-viewer')
export class MarkupViewer extends LitElement {
  render() {
    return html`
      <slot></slot>
      `;
  }
}